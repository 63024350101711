import moment from 'moment-timezone';

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

Array.prototype.max = function () {
  return Math.max.apply(null, this);
};

Array.prototype.min = function () {
  return Math.min.apply(null, this);
};

export const shortenSectorName = (sec) => {
  if (sec === "Consumer Defensive") return "Cons. Defens.";
  if (sec === "Communication Services") return "Comm. Serv.";
  if (sec === "Financial Services") return "Fin. Serv.";
  if (sec === "Consumer Cyclical") return "Cons. Cycl.";
  return sec;
};
export const isEmpty = (obj) => {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

export const shareStatsAxisMinMax = (shareStats, numFunds=false, shareStatsMinOverride=false, shareStatsMaxOverride=false) => {
  if (shareStats) {
    const numFundsIncluded = shareStats?.[0]?.includes('Number of Funds');
    if (numFundsIncluded) {
      if (numFunds) {

        const filteredShareStats = shareStats
          .slice(1)
          .filter((arr) => arr[1] > 0);

        const maxVal = Math.max(...filteredShareStats.map((arr) => arr[1])) * 1.2;
        const minVal = Math.min(...filteredShareStats.map((arr) => arr[1])) * 0.9;
        if (maxVal === -Infinity && minVal === Infinity) {
          return [0, 1];
        }

        return [minVal, maxVal];
      } else {
        const slicedShareStats = shareStats.slice(1);
        const pctAllFundsTurnedOff = slicedShareStats.every((arr) => arr[2] === -1);
        const shortFloatTurnedOff = slicedShareStats.every((arr) => arr[3] === -1);
        const insiderOwnershipTurnedOff = slicedShareStats.every((arr) => arr[4] === -1);
        const institOwnershipTurnedOff = slicedShareStats.every((arr) => arr[5] === -1);

        const filteredShareStats = (!pctAllFundsTurnedOff || !shortFloatTurnedOff) ? 
          slicedShareStats.filter((arr) => (arr[2] > 0 || arr[3] > 0) && arr[3] <= 100) : 
          slicedShareStats;

        let minVal, maxVal;

        if (shortFloatTurnedOff && !pctAllFundsTurnedOff) {
          minVal = Math.min(...filteredShareStats.map((arr) => arr[2]).filter(val => val > 0));
          maxVal = Math.max(...filteredShareStats.map((arr) => arr[2])) * 1.3;
        } else if (pctAllFundsTurnedOff && !shortFloatTurnedOff) {
          minVal = Math.min(...filteredShareStats.map((arr) => arr[3]).filter(val => val > 0));
          maxVal = Math.max(...filteredShareStats.map((arr) => arr[3])) * 1.3;
        } else if (pctAllFundsTurnedOff && shortFloatTurnedOff && !insiderOwnershipTurnedOff) {
          minVal = Math.min(...filteredShareStats.map((arr) => arr[4]).filter(val => val > 0));
          maxVal = Math.max(...filteredShareStats.map((arr) => arr[4])) * 1.3;
        } else if (pctAllFundsTurnedOff && shortFloatTurnedOff && insiderOwnershipTurnedOff && !institOwnershipTurnedOff) {
          minVal = Math.min(...filteredShareStats.map((arr) => arr[5]).filter(val => val > 0));
          maxVal = Math.max(...filteredShareStats.map((arr) => arr[5])) * 1.3;
        } else if (pctAllFundsTurnedOff && shortFloatTurnedOff && insiderOwnershipTurnedOff && institOwnershipTurnedOff) {
          minVal = 0;
          maxVal = 100;
        } else {
          minVal = Math.min(...filteredShareStats.map((arr) => Math.min(arr[2], arr[3])).filter(val => val > 0)) * 0.8;
          maxVal = Math.max(...filteredShareStats.map((arr) => Math.max(arr[2], arr[3]))) * 1.2;
        }

        if (minVal <= 1 || minVal === Infinity) {
          minVal = 0;
        }

        return [shareStatsMinOverride ? shareStatsMinOverride : minVal.toFixed(2), shareStatsMaxOverride ? shareStatsMaxOverride : maxVal.toFixed(2)];
      }
    } else {
      if (numFunds) return [false, false]
      return [shareStatsMinOverride ? shareStatsMinOverride : 0, shareStatsMaxOverride ? shareStatsMaxOverride : 100];
    }
  }
  return [0, 100];
}

export const instOwnMaxCalc = (shareStats, numFunds=false, instOwnMaxOverride=false) => {
  if (instOwnMaxOverride) return instOwnMaxOverride;
  if (shareStats) {
    const numFundsIncluded = shareStats?.[0]?.includes('Number of Funds');
    if (numFunds){
      if (!numFundsIncluded) return false
      const maxVal = shareStats
        .slice(1) // Skip the first array
        .map((arr) => arr[1]) // Get the value at the 3rd index of each array
        .reduce((max, val) => Math.max(max, val), 0); // Find the maximum value
      return maxVal * 1.3
    }
    else{
      const over100 = shareStats.filter((item) => item[3] > 100).length > 0;
      if (over100) {
        const over120 = shareStats.filter((item) => item[3] > 120).length > 0;
        return over120 ? 140 : 120;
      }
    }
  }
  return 110;
};

export const onSortNew = (sortBy, direction, data) => {
  const stringFields = ["Name", "Sector", "Industry", "name"]; // Add fields that should be sorted as strings here

  const dateFields = ["trkDt"];

  const sorted = [...data].sort((a, b) => {
    if (stringFields.includes(sortBy)) {
      const stringA = (a[sortBy] || "").toUpperCase(); // ignore upper and lowercase
      const stringB = (b[sortBy] || "").toUpperCase(); // ignore upper and lowercase
      if (stringA < stringB) {
        return direction === "asc" ? 1 : -1;
      }
      if (stringA > stringB) {
        return direction === "asc" ? -1 : 1;
      }
      return 0; // names must be equal
    } else if (dateFields.includes(sortBy)) {
      const dateA = new Date(a[sortBy]);
      const dateB = new Date(b[sortBy]);
      return direction === "asc" ? dateA - dateB : dateB - dateA;
    } else {
      return direction === "asc"
        ? a[sortBy] - b[sortBy]
        : b[sortBy] - a[sortBy];
    }
  });

  return sorted;
};
export const movingAvgs = (pseudoPriceData, sma) => {
  const dataPoints = [];
  const priceOnly = pseudoPriceData.map((item) => item[1]);
  for (let i = pseudoPriceData.length; i > sma; i--) {
    let dataPoint = [];
    const thisSMASeries = priceOnly.slice(i - 1 - sma, i - 1);
    let seriesSum = 0;
    for (let i = 0; i < thisSMASeries.length; i++) {
      seriesSum += thisSMASeries[i];
    }

    dataPoint.push(i - 1);
    dataPoint.push(seriesSum / sma);
    dataPoints.push(dataPoint);
  }
  return dataPoints;
};


export const AsofDate = (dt, hours=true, addHrs=16, addMins=0, colorTime=false, justDate=false) => {
  if (!dt) return justDate ? "" : "As of: ";
  let date = moment.tz(dt, "America/New_York");
  if (!date.isValid()) {
    return justDate ? "" : "As of: "; // Return an empty string if the date is invalid
  }
  if (addHrs) {
    date.add(addHrs, 'hours');
  }
  if (addMins) {
    date.add(addMins, 'minutes');
  }
  
  let formattedDate = date.format('MM/DD/YY');
  let formattedTime = date.format('HH:mm');
  
  let display = justDate ? formattedDate : `As of: ${formattedDate}`;

  return (
    <>
      {display}
      {colorTime && hours ? <span className='text-success'> {formattedTime} ET</span> : null}
    </>
  );
};


export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length === arr2.length)
    return arr1.every((element, index) => {
      if (arr2.includes(element)) return true;
      return false;
    });
  return false;
};

export const maxMinArray = (arr, type) =>
  type === "max" ? arr.max() : arr.min();

export const MoneyFormat = (num) => {
  if (num >= 1.0e6) num = (num / 1000).toFixed() * 1000;
  // Nine Zeroes for Billions
  return Math.abs(Number(num)) >= 1.0e9
    ? Math.abs(Number(num)) / 1.0e9 + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(num)) >= 1.0e6
    ? Math.abs(Number(num)) / 1.0e6 + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(num)) >= 1.0e3
    ? Math.abs(Number(num)) / 1.0e3 + "K"
    : Math.abs(Number(num));
};

export const isWeekendInEasternTime = (dayParams = [5, 6, 0, 1]) => {
  const currentDateTimeInEastern = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
  );
  const dayInEastern = currentDateTimeInEastern.getDay();
  return dayParams.includes(dayInEastern);
};

export const dateFormatData = (shareStats, fixInstInsidePct = false) => {
  return [
    ...shareStats.map((d) => {
      if (d[0] === "Date") return d;
      else {
        // Parse the date as UTC to ensure the date component is preserved
        let utcDate = moment.utc(d[0]);

        // Format the date to a string without time component
        const dateString = utcDate.format('YYYY-MM-DD');

        // Create a new date string that represents midnight in Eastern Time on the given date
        // We do this by appending 'T00:00:00-05:00' for EST or 'T00:00:00-04:00' for EDT,
        // depending on whether the date is in daylight saving time in the Eastern Time zone.
        // Note: This simplistic approach assumes -05:00 for EST and -04:00 for EDT,
        // which might not be accurate for all historical or future dates due to DST changes.
        // For more accuracy, consider logic to check if the date is in DST or not.
        const isDst = moment.tz(dateString, "America/New_York").isDST();
        const timeSuffix = isDst ? 'T00:00:00-04:00' : 'T00:00:00-05:00';
        let etDateString = dateString + timeSuffix;

        // Convert the new date string back to a Date object
        d[0] = new Date(etDateString);

        // Apply fixes if necessary
        if (fixInstInsidePct) {
          if (d[1] > 100) d[1] = 100;
          if (d[2] > 100) d[2] = 100;
          if (d[3] > 100) d[3] = 100;
        }

        return d;
      }
    }),
  ].sort(function (a, b) {
    return b[0] - a[0];
  });
};

export const addMonths = (dt, mths) => {
  const thisDt = new Date(dt);
  return new Date(thisDt.setMonth(thisDt.getMonth() + mths));
};

export const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const applyShortDate = (data) => {
  return data.map((d, i) => {
    if (i === 0) return d;
    else {
      const newDt = new Date(d[0]);
      d[0] = newDt.toLocaleDateString();
      return d;
    }
  });
};

export const formatLargeNumber = (num) => {
  if (Math.abs(num) > 1000000000000) {
    return (Math.abs(num) / 1000000000000).toFixed(2) + "T";
  } else if (Math.abs(num) > 1000000000) {
    return (Math.abs(num) / 1000000000).toFixed(2) + "B";
  } else if (Math.abs(num) > 1000000) {
    return (Math.abs(num) / 1000000).toFixed(2) + "M";
  } else {
    return num;
  }
};

export const formatPercentage = (num, noMult = false) => {
  if (num === null || num === undefined || num === "") return ''
  if (!noMult) num *= 100;
  // if (!num) return ;
  return num.toFixed(2) + "%";
};

export const checkIfImageExists = async (name) => {
  function checkImageExists(imageUrl) {
    return new Promise((resolve, reject) => {
      let imageData = new Image();
      imageData.onload = function () {
        resolve(true);
      };
      imageData.onerror = function () {
        reject(false);
      };
      imageData.src = imageUrl;
    });
  }
  async function init() {
    const url1 =
      "https://eodhistoricaldata.com/img/logos/US/" +
      name.toUpperCase() +
      ".png";
    const url2 =
      "https://eodhistoricaldata.com/img/logos/US/" +
      name.toLowerCase() +
      ".png";

    try {
      await checkImageExists(url1);
      return url1;
    } catch (error) {
      try {
        await checkImageExists(url2);
        return url2;
      } catch (err) {
        return false;
      }
    }
  }
  return init();
};
const timeDict ={
  '1': 12,
  '2': 24,
  '3': 36,
  '4': 48,
  '5': 60,
  '6': 72,
  '7': 84,
  '8': 96,
  '9': 108,
  '10': 120,
  '20': 240
}

export const calculateChartSettings = (chartData, time) => {
  const months = timeDict[time];

  let priceIndex = chartData[0].findIndex(header => header === 'Price')
  const prxIndex = priceIndex > 0 ? priceIndex: 1;

  chartData = chartData.filter((d) => d[1] !== null).slice(1, months * 4.333); // Skip the first array (labels), and filter out null values
  const priceData = chartData.map((data) => data[prxIndex]);
  const currTEdge = chartData[0][4];
  var significantDigits = calcSignificantDigits(priceData, currTEdge);

  let chartMin = Math.min(...priceData);
  let chartMax = Math.max(...priceData);
  let mostRecentPrice = priceData[0];
  const viewWindowMin = addMonths(chartData[1][0], -months);
  const viewWindowMax = addMonths(chartData[1][0], (months <= 36) ? 4 : 8);

  const within20PctTimeSeriesLow = mostRecentPrice <= chartMin * 1.2;

  if (!within20PctTimeSeriesLow) {
    significantDigits.sort((a, b) => b - a);
    var largestSignificantDigit = significantDigits[0];
    if (largestSignificantDigit > chartMax) {
      chartMax = largestSignificantDigit * 1.5;
    } else {
      chartMax = chartMax * 1.5;
    }
  } else {
    significantDigits.sort((a, b) => a - b);
    var smallestSignificantDigit = significantDigits[0];
    if (smallestSignificantDigit < chartMin) {
      chartMin = smallestSignificantDigit * 0.5;
    } else {
      chartMin = chartMin * 0.5;
    }
  }

  const [yAxisMin, yAxisMax] = within20PctTimeSeriesLow
    ? [chartMin, chartMax * 1.1]
    : [chartMin * 0.9, chartMax];

  // console.log('Chart Settings: ', yAxisMin, yAxisMax, significantDigits)

  return [
    yAxisMin.toFixed(2),
    yAxisMax.toFixed(2),
    viewWindowMin,
    viewWindowMax,
    significantDigits,
  ];
};

const calcSignificantDigits = (priceArray, currTEdge) => {
  const favoriteDigits = [
    0.5, 1, 2, 3, 4, 6, 8, 10, 20, 30, 40, 60, 80, 100, 120, 140, 160, 180, 200,
    300, 400, 500, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000,
  ];
  const mostRecentPrice = priceArray[0];
  const sigDigitsAllowed = 3;
  const isTrendingLower = currTEdge <= 20;

  // Determine the next significant digit based on the trend
  const nextSignificantDigit = isTrendingLower
    ? favoriteDigits.reverse().find((digit) => digit < mostRecentPrice)
    : favoriteDigits.find((digit) => digit > mostRecentPrice);

  const digitOccurrences = favoriteDigits.map((digit, index) => {
    const occurrences = priceArray.filter(
      (price) => Math.abs(price - digit) < digit * 0.05
    ).length;
    // Add a weighting to the first half of the array and specific numbers
    const specialNumbers = [10, 20, 40, 100, 200, 400, 600, 800, 1000];
    const centennialWeight = digit % 100 === 0 || specialNumbers.includes(digit) ? 4 : 1;

    const weight =
      (index < favoriteDigits.length / 2 ? 2 : 1) * centennialWeight;
    return { digit, occurrences: occurrences * weight };
  });

  // Add the potential future price to the list
  if (nextSignificantDigit) {
    digitOccurrences.push({ digit: nextSignificantDigit, occurrences: 1 });
  }

  digitOccurrences.sort((a, b) => b.occurrences - a.occurrences);

  const filteredOccurrences = digitOccurrences.filter((d) => d.occurrences > 0);

  // If the number of filteredOccurrences is less than sigDigitsAllowed
  if (filteredOccurrences.length < sigDigitsAllowed) {
    // Find the next higher digit from the largest digit with an occurrence > 0
    const maxDigit = Math.max(...filteredOccurrences.map((d) => d.digit));
    const favoriteDigitsReversed = isTrendingLower ? favoriteDigits : [...favoriteDigits].reverse();
    const nextDigit = isTrendingLower
      ? favoriteDigitsReversed.find((digit) => digit < maxDigit)
      : favoriteDigits.find((digit) => digit > maxDigit);

    // Add the next digit to the filteredOccurrences
    if (nextDigit) {
      filteredOccurrences.push({ digit: nextDigit, occurrences: 1 });
    }
  }

  // Ensure nextSignificantDigit always shows up in the result
  let result = filteredOccurrences
    .slice(0, sigDigitsAllowed)
    .map((d) => d.digit);

  if (
    nextSignificantDigit !== undefined &&
    !result.includes(nextSignificantDigit)
  ) {
    result = [...result, nextSignificantDigit].sort((a, b) => a - b);
    if (result.length > sigDigitsAllowed) {
      if (isTrendingLower) {
        // If trending lower and nextSignificantDigit is less than the minimum of the array, remove the maximum
        result = result.filter((num) => num !== Math.max(...result));
      } else {
        // If not trending lower, remove the minimum
        result = result.filter((num) => num !== Math.min(...result));
      }
    }
  } else if (
    result.length === sigDigitsAllowed - 1 &&
    nextSignificantDigit === Math.min(...result)
  ) {
    result = result.slice(1);
  }

  // Remove duplicates and limit the result to sigDigitsAllowed
  result = [...new Set(result)].slice(0, sigDigitsAllowed);
  return result;
};

export const emoji = (label) => {
  switch (label) {
    case "Real Estate":
      return "🏠";
    case "Technology":
      return "💻";
    case "Basic Materials":
      return "🧱";
    case "Consumer Cyclical":
      return "🛒";
    case "Consumer Defensive":
      return "🛡️";
    case "Communication Services":
      return "📞";
    case "Energy":
      return "⚡️";
    case "Financial Services":
      return "💵";
    case "Healthcare":
      return "🩺";
    case "Industrials":
      return "🏭";
    case "Utilities":
      return "💡";
    case "Crypto":
      return "🔒";
    case "ETF":
      return "ETF";
    default:
      return "❔";
  }
};

export const randomItemsFromArray = (numItems, array) => {
  const randomItemsArray = [];
  for (let i = 0; i < numItems; i++) {
    randomItemsArray.push(array[Math.floor(Math.random() * array.length)]);
  }
  return randomItemsArray;
};

export const mergeChartData = (prxData, otherData, newDataType) => {
  if (prxData.length !== otherData.length) {
    console.log("WARNING: Data lengths are not the same, cannot merge");
    return prxData;
  }

  if (newDataType === "volume") {
    let finalD = [prxData[0].concat(["Volume", "Avg. Vol"])];
    try {
      [...prxData]
        .splice(1, prxData.length - 1)
        .forEach(
          (d, i) =>
            (finalD = finalD.concat([
              [...d, otherData[i + 1][1], otherData[i + 1][2]],
            ]))
        );
    } catch (e) {
      console.log(e);
    }
    return finalD;
  } else if (newDataType === "MACD") {
    let finalD = [prxData[0].concat(["MACD"])];
    try {
      [...prxData]
        .splice(1, prxData.length - 1)
        .forEach(
          (d, i) => (finalD = finalD.concat([[...d, otherData[i + 1][1]]]))
        );
    } catch (e) {
      console.log(e);
    }
    return finalD;
  } else if (newDataType === "tEdge") {
    let finalD = [prxData[0].concat(["T. Edge"])];
    try {
      [...prxData]
        .splice(1, prxData.length - 1)
        .forEach(
          (d, i) => (finalD = finalD.concat([[...d, otherData[i + 1][1]]]))
        );
    } catch (e) {
      console.log(e);
    }
    return finalD;
  }
  return prxData;
};

// Array of special characters to be included in password

var specialCharacters = [
  "@",
  "%",
  "+",
  "\\",
  "/",
  "'",
  "!",
  "#",
  "$",
  "^",
  "?",
  ":",
  ",",
  ")",
  "(",
  "}",
  "{",
  "]",
  "[",
  "~",
  "-",
  "_",
  ".",
];

// Array of numeric characters to be included in password
var numericCharacters = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

// Array of lowercase characters to be included in password
var letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

var allCharacters = {
  specialCharacters: specialCharacters,
  numbers: numericCharacters,
  letters: letters,
};

export const generatePassword = (includeSpecial = true) => {
  var password = "";
  var loopCount = 25;
  for (let i = 0; i < loopCount; i++) {
    var keys = Object.keys(allCharacters);
    var array = allCharacters[keys[(keys.length * Math.random()) << 0]];
    let validation = true;
    while (validation) {
      if (array === allCharacters.letters) {
        if (Math.random() * 2 < 1) {
          const index = Math.floor(Math.random() * array.length);
          password += array[index].toUpperCase();
          validation = false;
        } else {
          const index = Math.floor(Math.random() * array.length);
          password += array[index];
          validation = false;
        }
      } else if (includeSpecial && array === allCharacters.specialCharacters) {
        const index = Math.floor(Math.random() * array.length);
        password += array[index];
        validation = false;
      } else if (array === allCharacters.numbers) {
        const index = Math.floor(Math.random() * array.length);
        password += array[index];
        validation = false;
      } else {
        loopCount += 1;
        validation = false;
      }
    }
  }
  return password;
};

export const generateQuarterEnds = latestFileDt => {
  const latestDate = new Date(latestFileDt);
  
  // Ensure the date is valid
  if (isNaN(latestDate.getTime())) {
      throw new Error('Invalid date');
  }

  // Calculate the year and month from the latestDate
  let year = latestDate.getFullYear();
  let month = latestDate.getMonth() + 1; // JavaScript months are 0-indexed

  // Initialize an array to store the quarter end dates
  const quarterEnds = [];

  // Continuously generate quarter ends until reaching or exceeding Q1 2020
  while (year > 2020 || (year === 2020 && month >= 3)) {
      // Identify the current quarter then subtract one to get the previous quarter
      const currentQuarter = Math.ceil(month / 3);
      let prevQuarter = currentQuarter - 1;

      // Adjust the year and previous quarter if necessary
      if (prevQuarter <= 0) {
          prevQuarter = 4; // Last quarter of the previous year
          year -= 1; // Move to the previous year
      }

      // Calculate the month of the previous quarter's end
      const prevQuarterEndMonth = prevQuarter * 3;
      // Create a date for the last day of the previous quarter
      const date = new Date(year, prevQuarterEndMonth, 0); // 0 gets the last day of the previous month

      // Format the date and add to the array
      const dateString = date.toISOString().split('T')[0];
      quarterEnds.unshift(dateString);

      // Prepare for the next iteration
      month = prevQuarterEndMonth - 2; // Set to the first month of the previous quarter
  }

  return quarterEnds.reverse();
}

export const createEdgarLink = (cik, accessionNum) => {
  const accNumPadded = accessionNum.toString().padStart(18, "0");
  const accNumFormatted = `${accNumPadded.slice(0, 10)}-${accNumPadded.slice(
    10,
    12
  )}-${accNumPadded.slice(12)}`;
  const url = `https://www.sec.gov/Archives/edgar/data/${cik}/${accNumPadded}/${accNumFormatted}-index.htm`;
  return url;
}

export const isTodayInReportingPeriod = () => {
    // Define quarter-end dates and their corresponding quarters
    const quarterEnds = ['03/31', '06/30', '09/30', '12/31'];
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
 
    // Get today's date
    const today = new Date();
    // const today = new Date("2024-08-01")
    const currentYear = today.getFullYear();

    // Helper function to parse date strings, add days, and format the date
    function addDaysAndFormat(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        // Format the date as MM/DD/YY for clarity and consistency
        const formattedDate = result.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
        return formattedDate;
    }

    // Check if today falls within 45 days after any quarter-end and return the corresponding quarter and date
    for (let i = quarterEnds.length - 1; i >= 0; i--) {
        // Construct the full date string for the quarter-end
        const quarterEndDateStr = `${quarterEnds[i]}/${currentYear}`;
        let quarterEndDate = new Date(quarterEndDateStr);

        // Adjust for year transition if necessary
        if (today < quarterEndDate) {
            quarterEndDate.setFullYear(currentYear - 1);
        }

        // Calculate the date 45 days after the quarter-end
        const endDatePlus45 = addDaysAndFormat(quarterEndDate, 45);

        // Check if today falls within the quarter-end and 45 days after
        if (today >= quarterEndDate && today <= new Date(endDatePlus45)) {
            return {
              quarter: quarters[i] + ' ' + quarterEndDate.getFullYear(),
              date45DaysAfter: endDatePlus45
            };
        }
    }


    // If today doesn't fall within any reporting period, return an informative response
    return { quarter: null, date45DaysAfter: null };
}

export const transactionCodes = {
  Planned: "",
  Unplanned: "",
  Acquired: "",
  Disposed: "",
  // General Transaction Codes
  P: "Market/private purchase",
  S: "Market/private sale",
  V: "Early report",

  // Rule 16b-3 Transaction Codes
  A: "Rule 16b-3(d) award/acquisition",
  D: "Issuer disposition under 16b-3(e)",
  F: "Securities payment by delivery/withholding",
  I: "Discretionary buy/sell under 16b-3(f)",
  M: "Derivative exercise/conversion under 16b-3",

  // Derivative Securities Codes
  C: "Derivative conversion",
  E: "Short derivative expiration",
  H: "Long derivative expiration with value",
  O: "Out-of-money derivative exercise",
  X: "In/at-money derivative exercise",

  // Other Section 16(b) Exempt Transaction and Small Acquisition Codes
  G: "Gift",
  L: "Small acquisition under 16a-6",
  W: "Will/law acquisition/disposition",
  Z: "Voting trust change",

  // Other Transaction Codes
  J: "Other acquisition/disposition",
  K: "Equity swap/similar",
  U: "Tender offer disposition",
};
