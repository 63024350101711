import React, { memo } from "react";
import useWindowDimensions from "./windowDimensions";
// import Typography from "@mui/material/Typography";
import { RingLoader } from "react-spinners";

// in house
import Logo from "./Logo/logo";
// import LoadingAnimation3D from "./loadingAnimation3D"; // Import the 3D Loading Animation component

const FullscreenOverlay = () => {
  const { width } = useWindowDimensions();

  const widthPct = (width) => {
    if (width < 500) return 25;
    if (width < 800) return 20;
    // if (width < 1200) return 15;
    if (width < 1500) return 15;
    else return 10;
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "10%",
        width: "100%",
        height: "100%",
      }}
    >
      <div className="row mb-5 justify-content-center mx-auto p-0">
        <Logo width={widthPct(width)} /> {/* Logo semi-centered near the top */}
      </div>
      {/* <Typography
        variant="h6"
        className="mx-auto text-center text-light"
        style={{
          fontSize: "1.8rem",
          marginBottom: "10px",
        }}
      >
        <span style={{ color: "#FF5A3F", fontWeight: "bold" }}>Trend Edge</span>: identify turning points in trends in a click.
      </Typography> */}
      <div className="row justify-content-center">
        <RingLoader size={150} color={"#ff5a3f"} />
      </div>
      {/* <LoadingAnimation3D /> */}
    </div>
  );
};

export default memo(FullscreenOverlay);
